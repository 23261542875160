import { useDialogContext } from '@ads-core/providers';
import { getDate, getMonth } from 'date-fns';
import { useRouter } from 'next/router';
import {
  Box,
  Text,
  Stack,
  TextLink,
  Heading,
  RichText,
  Button,
  ButtonUnset,
  Grid,
  GridItem,
  StackItem,
  Tag,
} from '@ads-core/components';
import { useMedia } from 'react-use';
import { mq } from '@ads-core/breakpoints';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { setOutageEndDate } from 'src/utils/outageEndDate';
import { setReplaceText } from 'src/utils';

import { OutageData, setCause } from '../OutageHero';
import { PolygonMap } from '../PolygonMap';

export type OutageDialogContentProps = {
  outageDetailsText?: { value?: string };
  outageDetailsLinkOne?: { value?: ButtonProps };
  outageDetailsLinkTwo?: { value?: ButtonProps };
  resolvedOutages?: boolean;
} & Omit<OutageData, 'topTasks' | 'followUpSteps'>;

export const OutageDialogContent = ({
  outageDetailsLinkOne,
  outageDetailsLinkTwo,
  outageDetailsText,
  resolvedOutages,
  ...outageData
}: OutageDialogContentProps) => {
  const { isOpen, closeDialog } = useDialogContext();
  const isDesktop = useMedia(mq.sm);
  const router = useRouter();
  const isMaintenance = outageData.cause === 'onderhoud' || outageData.cause === 'Geplande werkzaamheden';

  const onClose = (route?: string) => {
    closeDialog();

    if (route) {
      router.push(route);
    }
  };

  if (!outageData || (!outageData.status && !outageData.statusDescription)) return null;

  return (
    <Box
      paddingTop={{ initial: 10, md: 14 }}
      paddingInline={{ initial: 10, md: 20 }}
      paddingBottom={{ initial: 12, md: 14 }}
      asChild
    >
      <Stack gap={8} isFullWidth>
        {resolvedOutages ? 
          <Box><Tag>Opgelost</Tag></Box>
        : null}
        {isDesktop ? (
          <Heading size="h2">
            {modalTitle({
              isMaintenance,
              energyType: outageData.energyType ? outageData.energyType : '',
              postalCode: outageData.postalCode
            })}
          </Heading>
        ) : (
          <Box>
            <Text weight="bold">
            {modalTitle({
              isMaintenance,
              energyType: outageData.energyType ? outageData.energyType : '',
              postalCode: outageData.postalCode ? '' : undefined
            })}
            </Text>
            <Heading size="h2">{outageData.postalCode}</Heading>
          </Box>
        )}
        <Grid rowGap={2} columnGap={4} columns={2}>
          <GridItem columnStart="1" columnEnd="2">
            <Text size="description" weight="bold">
            {!resolvedOutages ? 'Verwachte eindtijd:' : 'Eindtijd'}
            </Text>
          </GridItem>
          <GridItem columnStart={'2'} columnEnd="-1">
            <Text size="description">
              {!resolvedOutages
                ? outageData.estimatedEndTime
                : `${displayDate(outageData.endDate)} om ${outageData.endTime}`}
            </Text>
          </GridItem>
          <GridItem columnStart="1" columnEnd="2">
            <Text size="description" weight="bold">
              {isMaintenance ? 'Start werkzaamheden:' :'Storing gemeld:'}
            </Text>
          </GridItem>
          <GridItem columnStart={'2'} columnEnd="-1">
            <Text size="description">
              {displayReportTime(outageData.reportTime)}
            </Text>
          </GridItem>
          <GridItem columnStart="1" columnEnd="2">
            <Text size="description" weight="bold">
              Oorzaak:
            </Text>
          </GridItem>
          <GridItem columnStart={'2'} columnEnd="-1">
            <Text size="description">{setCause(outageData.cause)}</Text>
          </GridItem>
          <GridItem columnStart="1" columnEnd="2">
            <Text size="description" weight="bold">
              Aantal getroffen:
            </Text>
          </GridItem>
          <GridItem columnStart={'2'} columnEnd="-1">
            <Text size="description">{outageData.affectedCustomers}</Text>
          </GridItem>
          <GridItem columnStart="1" columnEnd="2">
            <Text size="description" weight="bold">
              Referentienummer:
            </Text>
          </GridItem>
          <GridItem columnStart={'2'} columnEnd="-1">
            <Text size="description">{outageData.referenceNumber}</Text>
          </GridItem>
          <GridItem columnStart="1" columnEnd="2">
            <Text size="description" weight="bold">
              Postcodes:
            </Text>
          </GridItem>
          <GridItem columnStart={'2'} columnEnd="-1">
            <Text size="description">{outageData.affectedPostalCodes}</Text>
          </GridItem>
        </Grid>

        {outageDetailsText && !isMaintenance ? <RichText>{outageDetailsText.value}</RichText> : null}

        {outageDetailsLinkTwo?.value?.href || outageDetailsLinkOne?.value?.href ? (
          <Stack
            alignY={{ initial: 'start', md: 'center' }}
            alignX="start"
            isFullWidth
            direction={{ initial: 'column', md: 'row' }}
            gap={{ initial: 4, md: 6 }}
          >
            {outageDetailsLinkOne?.value?.href ? (
              <StackItem grow asChild>
                <Button onClick={() => onClose(outageDetailsLinkOne?.value?.href)}>
                  {outageDetailsLinkOne?.value?.text}
                </Button>
              </StackItem>
            ) : null}

            {outageDetailsLinkTwo?.value?.href ? (
              <StackItem grow>
                <TextLink asChild>
                  <ButtonUnset onClick={() => onClose(outageDetailsLinkTwo?.value?.href)}>
                    {setReplaceText(
                      outageDetailsLinkTwo.value.text,
                      {'{outageCause}': outageData.energyType === 'Elektriciteit' ? 'geen stroom' : 'geen gas'}
                    )}
                  </ButtonUnset>
                </TextLink>
              </StackItem>
            ) : null}
          </Stack>
        ) : null}

        <Stack gap={4}>
          <Stack>
            <Text size="description" weight="bold">
              Straten:
            </Text>
            <Text size="description">{outageData.affectedStreets}</Text>
          </Stack>
          {outageData?.rings || outageData?.affectedPostalCodes ? (
          <Box width="100%" height="176px" borderRadius="brandXl" overflow="hidden">
            <PolygonMap
              rings={outageData?.rings ? outageData.rings : undefined}
              postalCode={outageData?.affectedPostalCodes ? getFirstPostalCode(outageData.affectedPostalCodes) : undefined}
              isLoading={!isOpen && !outageData}
            />
          </Box>
          ) : null}
        </Stack>
      </Stack>
    </Box>
  );
};

// Set the title of the modal
const modalTitle = (props: {isMaintenance: boolean, energyType: string, postalCode?: string}): string => {

  if (props.isMaintenance && props.postalCode) {
    const energyType = props.energyType === 'Elektriciteit' ? 'stroom' : 'Gas'

    return `Gepland onderhoud ${energyType} op ${props.postalCode}`
  }

  if (props.isMaintenance && !props.postalCode) {
    const energyType = props.energyType === 'Elektriciteit' ? 'stroom' : 'Gas'

    return `Gepland onderhoud ${energyType}`
  }

  return props.energyType === 'Elektriciteit' ? 'Stroomstoring' : 'Gasstoring'
}

// Refactor the string in case of a date and adds a year.
const displayReportTime = (reportTime?: string | null | undefined) => {
  if(reportTime && !reportTime.startsWith('vandaag')) {
    const splitReportTime = reportTime.split(" ");

    if(splitReportTime.length > 2) {
      const date = `${splitReportTime[0]} ${splitReportTime[1]}`;
      const time = splitReportTime.slice(2, splitReportTime.length).toString().replace(',', ' ');

      const outageDate = setOutageEndDate(date);

      return `${outageDate} ${time}`
    }
  }

  return reportTime
}

// Check if the date is today and set the string 'vandaag' if that's the case.
const displayDate = (date: string | undefined | null): string => {
  if (date) {
    const currentDate = new Date();
    const inputDate = new Date(date);

    const currentDay = getDate(currentDate);
    const currentMonth = getMonth(currentDate);
    const inputDay = getDate(inputDate);
    const inputMonth = getMonth(inputDate);

    if (currentDay === inputDay && currentMonth === inputMonth) {
      return 'vandaag';
    }

    return setOutageEndDate(date);
  }

  return '';
};

// selects the first postal code from a list of postalCodes.
const getFirstPostalCode = (affectedPostalCodes?: string): string | undefined => {
  if (affectedPostalCodes) {
    const postalCodes = affectedPostalCodes.split(', ')
    const postalCode = postalCodes.length ? postalCodes[0] : undefined;

    return postalCode ? postalCode.substring(0, 4) + ' ' + postalCode.substring(4) : postalCode;
  }

  return undefined;
};