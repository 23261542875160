import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  ProgressDetails,
  SubpageHeroSitecore,
  TopTasksSitecore,
  Text,
  Stack,
  TextLink,
  FollowUpSteps,
  Dialog,
  Heading,
  RichText,
  ScrollLinkWrapper,
} from '@ads-core/components';
import { capitalizeLocationName } from 'src/utils/format';
import { FollowUpStepsProps, FollowUpStepProps, FollowUpStepCardType } from '@ads-core/types';
import { renderJssLink } from '@alliander-fe/jss-utils';
import {
  ChevronRightIcon,
  FlagIcon,
  NoElectricalConnectionIcon,
  NoGasConnectionIcon,
} from '@ads-core/icons';
import { PolygonMap } from 'src/components/PolygonMap';
import { OutageFollowUpStepsProps, OutageHeroProps, OutageTasksProps } from 'components/OutageHero';
import { forceString, setReplaceText } from 'src/utils';
import { IntegrationApiFeaturesOutagesContractsDataOutage as OutageProps } from '@ads-core/styles/api/data-contracts';
import { useDialogContext } from '@ads-core/providers';
import { OutageDialogContent, OutageDialogContentProps } from '../OutageModal';

export type OutageData = {
  title: string;
  statusPhases?: string[];
  statusDescription?: string;
  postalCode?: string;
  city: string;
  topTasks: MappedTopTasksProps[];
  followUpSteps?: FollowUpStepsProps;
} & OutageProps;

type FetchStatus = 'initial' | 'loading' | 'done' | 'error' | 'notFound';

export const OutageHeroComponent = (props: OutageHeroProps['fields']) => {
  const [outageData, setOutageData] = React.useState<OutageData>();
  const [outageDialogData, setOutageDialogData] = React.useState<OutageDialogContentProps>();
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial');
  const [isMaintenance, setIsMaintenance] = React.useState<boolean>(false);
  const { isOpen, openDialog } = useDialogContext();
  const router = useRouter();

  React.useEffect(() => {
    if (!isOpen) {
      setOutageDialogData(undefined);
    }
  }, [isOpen]);

  const openOutageDialog = () => {
    if (outageData) {
      setOutageDialogData({
        ...outageData,
        outageDetailsText: props?.outageDetailsModalText,
        outageDetailsLinkOne: props?.outageDetailsModalLinkOne,
        outageDetailsLinkTwo: props?.outageDetailsModalLinkTwo,
      });
      openDialog();
    }
  };

  const requestOutageData = React.useCallback(
    async (referenceNumber: string, postalCode: string, city: string) => {
      setFetchStatus('loading');

      const response = await getOutageData(referenceNumber);
      let title = '';

      if (!response || !response.referenceNumber) {
        if (props?.redirectPageNoOutageFound?.value?.href) {
          router.push({
            pathname: props?.redirectPageNoOutageFound?.value?.href,
            query: {
              postcode: postalCode,
              plaats: city?.toLowerCase(),
            },
          });
        }

        setFetchStatus('notFound');
        return;
      }

      if (response.errorMessage) {
        setFetchStatus('error');
        return;
      }

      const cause = setCause(response.cause);
      const isMaintenance = response.cause === 'Geplande werkzaamheden';
      setIsMaintenance(isMaintenance);

      const status = setStatus({
        outageProps: props,
        status: response.status,
        cause: cause,
      });

      const displayPostalCode = setDisplayPostalCode(postalCode);

      if (isMaintenance) {
        let replaceCause = cause

        if (response.energyType === 'Elektriciteit') {
          replaceCause = 'stroom'
        } 

        if (response.energyType === 'Gas') {
          replaceCause = 'gas'
        }

        title = setReplaceText(props?.maintenanceText?.value ? props?.maintenanceText?.value : '', {
          '{cause}': replaceCause,
          '{postalCode}': displayPostalCode,
        });
      } else {
        let replaceCause = cause

        if (response.energyType === 'Elektriciteit') {
          replaceCause = 'een stroomstoring'
        } 

        if (response.energyType === 'Gas') {
          replaceCause = 'een gasstoring'
        }

        title = setReplaceText(props?.outageText?.value ? props?.outageText?.value : '', {
          '{cause}': replaceCause,
          '{postalCode}': displayPostalCode,
        });
      }

      const filteredTopTasks = getFilteredTopTasks(props?.outageTasks);

      const followUpSteps = getFollowUpStepsByEnergyType(
        response.energyType,
        props?.followUpStepsSectionElectricity,
        props?.followUpStepsSectionGas
      );

      setOutageData({
        ...response,
        affectedPostalCodes: response.affectedPostalCodes
          ? setReplaceText(response.affectedPostalCodes, { ';': ', ' })
          : '',
        affectedStreets: response.affectedStreets
          ? setReplaceText(response.affectedStreets, { ';': ', ' })
          : '',
        title,
        postalCode: setDisplayPostalCode(postalCode),
        status: status.label,
        statusDescription: status.description,
        statusPhases: status.phases,
        topTasks: filteredTopTasks,
        followUpSteps: followUpSteps,
        city,
        cause: cause,
      });

      setFetchStatus('done');
    },
    [props, router]
  );

  React.useEffect(() => {
    const referentieQuery = forceString(router.query.referentie);
    const postalCodeQuery = forceString(router.query.postcode);
    const cityQuery = forceString(router.query.plaats);

    if (referentieQuery) {
      requestOutageData(referentieQuery, postalCodeQuery, cityQuery);
      return;
    }

    const filteredTopTasks = getFilteredTopTasks(props?.noOutageTasks);
    const postalCode = setDisplayPostalCode(postalCodeQuery);
    const title = setReplaceText(props?.noOutageText?.value ? props?.noOutageText?.value : '', {
      '{postalCode}': postalCode ? postalCode : '',
    });

    setOutageData({
      title: title,
      postalCode: postalCode,
      city: cityQuery,
      topTasks: filteredTopTasks,
    });

    setFetchStatus('notFound');
  }, [props, requestOutageData, router]);

  const variableBlock = () => {
    if (!outageData || (!outageData.status && !outageData.statusDescription)) return;

    return (
      <Box
        bg="containerLight"
        paddingTop={{ initial: 10, md: 14 }}
        paddingBottom={{ initial: 8, md: 10 }}
        paddingLeft={{ initial: 10, md: 16 }}
        paddingRight={{ initial: 10, md: 12 }}
        asChild
      >
        <Stack alignX="start" gap={4} alignY="justify" isFullHeight>
          <Box>
            {outageData ? (
              <Box paddingBottom={4} asChild>
                <Heading size="h4" as="h3">
                  {setProgressTitle({cause: outageData?.cause, energyType: outageData?.energyType, city: outageData.city})}
                </Heading>
              </Box>
            ) : null}
            {outageData.statusDescription ? (
              <RichText>{outageData.statusDescription}</RichText>
            ) : null}
          </Box>

          <ProgressDetails
            currentStatus={outageData.status ? outageData.status : ''}
            statusOptions={outageData?.statusPhases}
          />
          <TextLink afterIcon={<ChevronRightIcon />} asChild>
            <button onClick={() => openOutageDialog()}>Bekijk alle details</button>
          </TextLink>
        </Stack>
      </Box>
    );
  };

  return (
    <>
      {outageDialogData ? (
        <Dialog>
          <OutageDialogContent {...outageDialogData} />
        </Dialog>
      ) : null}
      <SubpageHeroSitecore
        text={<Heading size="h1">{outageData?.title}</Heading>}
        tasks={
          outageData?.topTasks ? (
            <TopTasksSitecore
              tasks={outageData?.topTasks}
              variableBlock={variableBlock()}
              variableBlockPosition={'start'}
              variableBlockSpan={2}
            />
          ) : undefined
        }
        explanation={
          <Box paddingTop={{ initial: 4, md: 0 }} asChild>
            <Stack alignY="end" gap={2}>
              {fetchStatus === 'done' && outageData ? (
                <>
                  <Stack direction="row" gap={2}>
                    <FlagIcon size="xs" />
                    <Stack direction="row" gap={1} wrap>
                      <Text weight="bold">Verwachte eindtijd:</Text>
                      <Text>{outageData.estimatedEndTime}</Text>
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap={2}>
                    {outageData.energyType === 'Elektriciteit' ? (
                      <NoElectricalConnectionIcon size="xs" />
                    ) : (
                      <NoGasConnectionIcon size="xs" />
                    )}
                    <Stack direction="row" gap={1} wrap>
                      <Text weight="bold">
                        {isMaintenance ? 'Start werkzaamheden:' : 'Storing gemeld:'}
                      </Text>
                      <Text>{outageData.reportTime}</Text>
                    </Stack>
                  </Stack>
                </>
              ) : null}
              {fetchStatus === 'notFound' && outageData && props?.noOutageLink?.value?.href ? (
                <Stack direction="row" gap={2}>
                  <NoElectricalConnectionIcon size="xs" />
                  <TextLink href={props.noOutageLink.value.href}>
                    {props.noOutageLink.value.text}
                  </TextLink>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        }
        image={
          <Stack isFullHeight isFullWidth>
            <PolygonMap
              postalCode={outageData?.postalCode}
              rings={outageData?.rings ? outageData.rings : undefined}
              isLoading={(fetchStatus === 'initial' || fetchStatus === 'loading') && !outageData}
            />
          </Stack>
        }
      />

      {outageData?.followUpSteps?.followUpSteps ? (
        <ScrollLinkWrapper anchorId="stappenplan">
          <FollowUpSteps {...outageData.followUpSteps} />
        </ScrollLinkWrapper>
      ) : null}
    </>
  );
};

const setProgressTitle = ({cause, energyType, city}: {cause?: string | null | undefined, energyType?: string | null | undefined, city?: string}): string => {
  const displayCity =  capitalizeLocationName(city ? city : '')
  let displayEnergyType = ''

  if (energyType && energyType === 'Elektriciteit') {
    displayEnergyType = 'stroom'
  }

  if (energyType && energyType === 'Gas') {
    displayEnergyType = 'gas'
  }
  
  if(cause === 'onderhoud' || cause === 'Onderhoud') {
    return `Gepland onderhoud ${displayEnergyType} in ${displayCity}`
  }

  return `${firstLetterToUpperCase(cause)} in ${capitalizeLocationName(city ? city : '')}`
}

type OutageApiResponse = {
  success?: boolean;
  errorMessage?: string;
} & OutageProps;

// Get de data form the API
export const getOutageData = async (reference: string): Promise<OutageApiResponse> => {
  try {
    const uri = `/api/outages/specific-outage/${reference}`;
    const response = await fetch(uri);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(response.statusText);
    }
  } catch (e) {
    console.error('Rest api call for congestions failed:', e);

    return {
      success: false,
      errorMessage: 'Rest api call for congestions failed',
    };
  }
};

export const setCause = (cause: string | null | undefined): string => {
  switch (cause) {
    case 'Geplande werkzaamheden':
      return 'onderhoud';
    case 'Spontane storing':
      return 'een storing';
    case 'onderhoud':
      return 'Onderhoud';
    default:
      return cause ? cause : '';
  }
};

type StatusProps = {
  cause?: string;
  status?: string | undefined | null;
  outageProps?: OutageHeroProps['fields'];
};

const setStatus = ({ cause, status, outageProps }: StatusProps) => {
  if (cause === 'onderhoud') {
    const maintenancePhases: string[] = [];

    if (outageProps?.maintenancePhaseOneLabel?.value) {
      maintenancePhases.push(outageProps?.maintenancePhaseOneLabel?.value);
    }

    if (outageProps?.maintenancePhaseTwoLabel?.value) {
      maintenancePhases.push(outageProps?.maintenancePhaseTwoLabel?.value);
    }

    if (outageProps?.maintenancePhaseThreeLabel?.value) {
      maintenancePhases.push(outageProps?.maintenancePhaseThreeLabel?.value);
    }

    switch (status) {
      case 'monteur onderweg':
        return {
          label: outageProps?.maintenancePhaseOneLabel?.value,
          description: outageProps?.maintenancePhaseOneText?.value,
          phases: maintenancePhases,
        };
      case 'monteur ter plaatse':
        return {
          label: outageProps?.maintenancePhaseTwoLabel?.value,
          description: outageProps?.maintenancePhaseTwoText?.value,
          phases: maintenancePhases,
        };
      default:
        return {
          label: outageProps?.maintenancePhaseThreeLabel?.value,
          description: outageProps?.maintenancePhaseThreeText?.value,
          phases: maintenancePhases,
        };
    }
  }

  const outagePhases: string[] = [];

  if (outageProps?.outagePhaseOneLabel?.value) {
    outagePhases.push(outageProps?.outagePhaseOneLabel?.value);
  }

  if (outageProps?.outagePhaseTwoLabel?.value) {
    outagePhases.push(outageProps?.outagePhaseTwoLabel?.value);
  }

  if (outageProps?.outagePhaseThreeLabel?.value) {
    outagePhases.push(outageProps?.outagePhaseThreeLabel?.value);
  }

  switch (status) {
    case 'monteur onderweg':
      return {
        label: outageProps?.outagePhaseOneLabel?.value,
        description: outageProps?.outagePhaseOneText?.value,
        phases: outagePhases,
      };
    case 'monteur ter plaatse':
      return {
        label: outageProps?.outagePhaseTwoLabel?.value,
        description: outageProps?.outagePhaseTwoText?.value,
        phases: outagePhases,
      };
    default:
      return {
        label: outageProps?.outagePhaseThreeLabel?.value,
        description: outageProps?.outagePhaseThreeText?.value,
        phases: outagePhases,
      };
  }
};

type MappedTopTasksProps = {
  link: React.ReactElement;
  arrowLink?: React.ReactElement;
  color: { value: string };
  isAnchor: boolean;
  href: string;
};

const getFilteredTopTasks = (tasks?: OutageTasksProps[]): MappedTopTasksProps[] => {
  if (!tasks) return [];

  const toptasks = tasks.map(({ fields: { link, title, color } }) => {
    const taskText = title.value?.toString();

    const linkField: LinkField = {
      ...link,
      value: {
        ...link.value,
        text: taskText || link.value.text,
      },
    };

    return {
      link: renderJssLink(
        linkField,
        <Box position="relative" zIndex="1" as="span">
          {taskText}
        </Box>
      ),
      arrowLink: renderJssLink(linkField),
      color,
      isAnchor: Boolean(link.value.anchor),
      href: link.value.href,
    };
  });

  type MappedTopTasks = (typeof toptasks)[number];
  type RequiredTopTasks = Omit<MappedTopTasks, 'link'> & {
    link: NonNullable<MappedTopTasks['link']>;
  };

  return toptasks.filter((t): t is RequiredTopTasks => !!t.link);
};

// resets the postalcode to numbers and letters and add a space in the correct position.
const setDisplayPostalCode = (postalCodeInput: string) => {
  const postalCode = postalCodeInput.toUpperCase().replace(/ /g, '');
  return postalCode.substring(0, 4) + ' ' + postalCode.substring(4);
};

const firstLetterToUpperCase = (text?: string | undefined | null) => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
};

const getFollowUpStepsByEnergyType = (
  energyType: string | null | undefined,
  electricTopTasks: OutageFollowUpStepsProps | undefined,
  gasTopTasks: OutageFollowUpStepsProps | undefined
): FollowUpStepsProps | undefined => {
  switch (energyType) {
    case 'Elektriciteit':
      return {
        tag: electricTopTasks?.fields?.tag?.value,
        title: electricTopTasks?.fields?.title?.value,
        followUpSteps: FollowUpStepsSection(electricTopTasks),
      };
      break;

    case 'Gas':
      return {
        tag: gasTopTasks?.fields?.tag?.value,
        title: gasTopTasks?.fields?.title?.value,
        followUpSteps: FollowUpStepsSection(gasTopTasks),
      };
    default:
      return undefined;
  }
};

const FollowUpStepsSection = (props: OutageFollowUpStepsProps | undefined): FollowUpStepProps[] => {
  if (!props) {
    return [];
  }

  return props.fields.followUpSteps.map((step) => {
    return {
      moreDetails: true,
      moreDetailsText: step.fields.moreDetailsText.value,
      moreDetailsTitle: step.fields.moreDetailsTitle.value,
      moreDetailsTag: step.fields.moreDetailsTag.value,
      moreDetailsButton: step.fields.moreDetailsButton.value,
      text: step.fields.text.value,
      title: step.fields.title.value,
      tag: step.fields.tag.value,
      type: step.fields.type.value as FollowUpStepCardType,
    };
  });
};
