import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/cpBCwW2AKOMOQctXvl+nBZ7BssJEtK3Ta2a7uNk2LffUk1KSDOsEATxHB+coYcaj6K9b9w2dsDWFN9+62q7u5e/4e/qt/xLSaMf890JH07/cx1S3pC2MT1XHekGxObpZ3rnnS3BXSruR6UfaISNyl6o+itsn835el9DHN9p+TfK/sPij4q+r1S5/dKPnsl/w/KPh+VfT4p53pQ8jwo+lHRT4r+WdHPSv4Xxf+q+H9R/L8q+qOyz4300fPvXH9iHXbOjHP9mXRou12zm+tglMDAhG2XW7c1woDKIwP7I1cr1zgtiteiBOXgEJUbBozaZrVebwSywKyFFTQhCQPD5lfgfSMMnUI/aLgB87ZuNqutNAzTipDSRhjG72VcRVmSe62+zFxcv3VWZsXQbfzWrOUBmbqhH+PQCwNjN/gxjNLA3I3j6AZxr4DBG/uhz7Y6aCU5auc4aSsm+JZNBFkrpm9NK4ysFeF3PbeHy6k9D4erMGoMwpefrdJIhEft4t202/KkHfRZKQ0aBQcEJThqLzy0yunRKVVGrxkmFofYWxQGZtFgSj0IQ5ouXtgZuYJZpH1GFP0JW+VyI7O4tIZ+hIFYNKdbBYY/E3+c77v2TxsXFcKisovK1AbfLCYvB3WMMdA3a2uHCGV3cDZGeFmDWFsbLMeIdQqNsgQa2u5lCWDtEcKUFpkiKmte3EMdvOFUfE3P3qSyt0kmxhRes6pjiBimb0gDhnIS4wNiA29E0ahP7Q+X4VqZ6ZcXl2/JUHAs3jPqY3zKvr28H3rl9t7PfUpMIHU3d7oJca++UvDDj4ufmT5qsGhTBT5osBwUtvGoxdAmC/ysxdC6G140Jq4aEzxedH3fdIMwcFdrd303SiC5q41dj60YDHCaMGw3ZFs9TdwJ8Xl6wmLmNoVHypN7ykTqYobl1z9htmz+jtXrh/CgpubNH0L0hUx5gs+TiqWkqIlBfijqYIX41L4wj0W9C/Ng1Lgwj0Zk2jwaYWjzaMScy6MRZS7fl7jy+Q4EVsgzI6ZingPhlPJoxFKT7/vANcudD1y0XD5y1XKZEPrnfLztH9rq3fFru6iW5337aVFd6J19dxnOe/Hy4Kn91xYQckHCw7O8y8RrsajEGdTipcczfOGpPJZcb8WH/cSuojXx5J7fFmcKrg5K181hyZWI4negUF3JlSiCPIHArmJIdLHEhkslV8Yoz7V9cf3vfyHuM7j5DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VXS2+jMBC+91dYlSqBVEeQNElLLnvY4x73sLeVg01wCzYyTtt0lf++xrxsYhISqZco4Hl8M/PNjJl9pYcFIQH4dwcApmWRoUMEGGdko158UCzTCMyXD9VTSugulREIg+Kzet5ygYmAkhdKpPgEGJUpwUAKxMoCCcJkJVXwkkrKWQTQtuTZXmrLWmkVKLvHu1mDYUbeCdNAROtoIMAx1ucZSVzH8b6UPCfipwbyizKipRucMc+4iMA7Eh6Ef8OSLli4CGP/goloSxIuyCM4J4MSSUTtDMVvO8H3DE90yPMCscM1kNcXLDgQn4hcAXjgripS40Hrx5xJVeoI3N8blGk5MsIZgTDdlxFYBg/jHLFY0FAm0P8qhikAeVT/zZAknjL1CKD69SuRL0gZJp/Kw5BARujfB90k6EXkcAL0UAPunQmiVOm7dpYjsaMMbjMev8FSIiErdBXoXv1Mxca6/ro81BHHKIu9NhBXsH+8Lk535rrowy5zcG7H0jWgGdR1fRe2LXGVCasT5oOKOJhrqy/WfheS40QZbuP6jXbTAGET0OL2WBY3z4OnG4Aub9BZneF/x1drHazGG71n9FNN6XbPWQSvGW0sNkcezvd/0FN4YXN4PTWcQXcThk/IY+VpPSXq2vC3Bx3aM2gdMZnCOKUZ9tQc9rv9ZB5U28XX6F1TLTDMPUcZKmWtZgQ9mGad+Is+TVRKYEm/iEK3qgO3B457uDWpWs7tidg+u9NRm6rTANRrepLMUCfzVXU9TQ6wK1esfomwRnOSEe0JZXTHIJUkL005ZwPNtXUd8UcDeBUErkJNXE1dLtENg2J7w5SOp+mkWudHTjBFoIwFUbdIxDDwcsWfrpvmqtdrZs3MO6+RZE21KhXHXshanBdkV41QgTCmbAddk/7VHyoZ3B1uUQBBqECfaFiQBl0+sS5a0Mn104XvgLVsYI1xv73gr1sLgzuFfcuxwls3cTUaTzUqU2AwRyLGpRclVLTTwG8suCYIVNOvhjBisR9AnQHKMnVlbi2gveSba+2bNb5Yn9Af1uFyDk5J1ONsbiO6fFB9yxm0MgfAuYSMkXSauZFR7WonY7ieBP1iM2P13HKpVWnfGDq40em/N42MBBtnviZ8XhgeiNPDlIv+8e74HwU79qx/DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var cardRecipe = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{hasConclusion:{false:{initial:'zhy3ee8'}}},compoundVariants:[],defaultVariants:{hasConclusion:false},baseClassName:'zhy3ee7'});
export var cardsWrapper = 'zhy3ee1';
export var companyTag = 'zhy3ee3';
export var conclusionBlock = 'zhy3ee6';
export var counter = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{variant:{company:{initial:'zhy3eea'},customer:{initial:'zhy3eeb'},checked:{initial:'zhy3eec'}},index:{odd:{initial:'zhy3eed'},even:{initial:'zhy3eee'}}},compoundVariants:[],defaultVariants:{variant:'company',index:'even'},baseClassName:'zhy3ee9'});
export var customerTag = 'zhy3ee4';
export var dashedLine = 'zhy3ee0';
export var solvedTag = 'zhy3ee5';
export var tag = 'zhy3ee2';