import {
  FastEndpointsErrorResponse as ErrorResponse,
  IntegrationApiFeaturesOutagesContractsResponsesGetOutagesOnLocationResponse as OutagesOnLocationResponse,
} from '@alliander-fe/api/data-contracts';
import { isErrorResponse } from './utils';

// Get de data form the API
export const getOutagesOnLocation = async (
  city: string,
  resolved?: boolean,
  amount?: number
): Promise<OutagesOnLocationResponse | ErrorResponse> => {
  try {
    let additionalRequest = '';

    if (resolved) {
      additionalRequest = `?resolved=${resolved}`;
    }

    if (amount) {
      additionalRequest = `?amount=${amount}`;
    }

    if (resolved && amount) {
      additionalRequest = `?resolved=${resolved}&amount=${amount}`;
    }

    const uri = `/api/outages/on-location/${city}${additionalRequest}`;
    const response = await fetch(uri);

    if (response.ok) {
      return await response.json();
    } else {
      throw await response.json();
    }
  } catch (e) {
    if (isErrorResponse(e)) {
      return e;
    }

    console.error('Rest api call for Outages On Location failed:', e);

    return {
      errors: {
        api: [`Rest api call for Outages On Location failed: ${e} `],
      },
    };
  }
};
