import React from 'react';
import { useState } from 'react';
import { useDialogContext } from '@ads-core/providers';
import { IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityDetailsResponse } from '@alliander-fe/api/data-contracts';
import { DialogWithContent, Loader, PostalCodeCheckForm } from '@ads-core/components';
import { DialogContentProps, PostalCodeCheckFormProps } from '@ads-core/types';
import { postalCodePattern } from '@ads-core/utils';
import { useTrackingContext } from '@liander/context';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { useRouter } from 'next/router';

export type OutageCheckFormProps = {
  outagecheck?: OutageCheckProps;
  color?: PostalCodeCheckFormProps['color'];
};

export const OutageCheckForm = ({ outagecheck, color }: OutageCheckFormProps) => {
  const { isOpen, openDialog } = useDialogContext();
  const { trackPostalcodeCheckSuccess, trackPostalcodeCheckError } = useTrackingContext();
  const [dialogData, setDialogData] = useState<DialogContentProps>({});
  const [postalCodeError, setPostalCodeError] = useState<string>();
  const [houseNumberError, setHouseNumberError] = useState<string>();
  const router = useRouter();

  React.useEffect(() => {
    if (!isOpen)
      setDialogData({
        title: (
          <>
            Laden... <Loader />
          </>
        ),
      });
  }, [openDialog, isOpen]);

  if (!outagecheck) return null;

  return (
    <>
      <PostalCodeCheckForm
        title={outagecheck.title.value ?? 'Controleer op actuele storingen in de buurt.'}
        postalCode={outagecheck.postcode.value ?? 'Postcode'}
        postalCodeError={postalCodeError}
        houseNumberAddition="Huisnr. en toevoeging"
        houseNumberAdditionError={houseNumberError}
        submitButton={outagecheck.button.value ?? 'Controleren'}
        color={color}
        handleOnSubmit={async (data) => {
          const { houseNumber, postalCode, addition } = data;
          const houseNumberTag = `${houseNumber}${addition && `-${addition}`}`;
          const redirectNoOutagesPage = outagecheck?.noOutagesPage.value.href;
          const redirectOutagesPage = outagecheck?.outagePage.value.href;
          let hasError = false;

          const tag =
            outagecheck.tag.value !== ''
              ? `${outagecheck.tag.value} <strong>${postalCode}</strong>, nr. <strong>${houseNumberTag}</strong>`
              : `Hoi bewoner op <strong>${postalCode}</strong>, nr. <strong>${houseNumberTag}</strong>`;

          const postalCodeIsValid = postalCodePattern.value.test(postalCode);

          if (!postalCodeIsValid) {
            const error = postalCodePattern.message;
            hasError = true;
            trackPostalcodeCheckError({ error });
            setPostalCodeError(error);
          } else {
            setPostalCodeError(undefined);
          }

          if (!houseNumber) {
            const error = 'Vul een huisnummer in';
            hasError = true;
            trackPostalcodeCheckError({ error });
            setHouseNumberError(error);
          } else {
            setHouseNumberError(undefined);
          }

          if (hasError) return;

          if (!redirectNoOutagesPage && !redirectOutagesPage) {
            openDialog();
          }

          try {
            let apiRequest = `${postalCode}/${houseNumber}`;
            if (addition) apiRequest = `${apiRequest}/${addition}`;

            const request = await fetch(`/api/service-availability/details/${apiRequest}`);

            if (!request.ok) {
              if (request.status === 404) {
                const message = 'Adres niet gevonden.';

                if (redirectNoOutagesPage && redirectOutagesPage) {
                  openDialog();
                }

                trackPostalcodeCheckError({ error: message });

                return setDialogData({
                  tag,
                  title: message,
                });
              } else {
                const message = 'Er is iets misgegaan.';

                if (redirectNoOutagesPage && redirectOutagesPage) {
                  openDialog();
                }

                trackPostalcodeCheckError({ error: message });
                return setDialogData({
                  tag,
                  title: message,
                });
              }
            }

            const res: IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityDetailsResponse =
              await request.json();

            const req = { data: res };

            // Track success
            trackPostalcodeCheckSuccess();

            console.log('req.data', req.data)


            if (
              req.data.electricityNetwork?.isHealthy &&
              req.data.electricityNetwork?.isOperational &&
              req.data.gasNetwork?.isHealthy &&
              req.data.gasNetwork?.isOperational
            ) {
              const { href, text } = outagecheck.noOutageButton.value;
              // No outage, operational electricity and gas

              if (redirectNoOutagesPage) {
                router.push({
                  pathname: redirectNoOutagesPage,
                  query: {
                    postcode: postalCode,
                    plaats: req.data.address?.city?.toLowerCase(),
                  },
                });
              }

              return setDialogData({
                tag,
                title: outagecheck.noOutageTitle.value,
                description: outagecheck.noOutageElectricityAndGasText.value,
                button: href && text ? { href, text } : undefined,
              });
            } else if (
              req.data.electricityNetwork?.isHealthy &&
              !req.data.electricityNetwork?.isOperational &&
              req.data.gasNetwork?.isHealthy &&
              !req.data.gasNetwork?.isOperational
            ) {
              const { href, text } = outagecheck.noElectricityAndNoGasButton.value;
              // Not operational electricity and gas
              
              openDialog();

              return setDialogData({
                tag,
                title: outagecheck.noElectricityAndNoGasTitle.value,
                description: outagecheck.noElectricityAndNoGasText.value,
                button: href && text ? { href, text } : undefined,
              });
            } else if (
              req.data.electricityNetwork?.isHealthy &&
              req.data.electricityNetwork?.isOperational &&
              !req.data.gasNetwork?.isOperational &&
              req.data.gasNetwork?.isHealthy
            ) {
              const { href, text } = outagecheck.noOutageButton.value;
              // No outage, operational electricity
              
              openDialog();

              return setDialogData({
                tag,
                title: outagecheck.noOutageTitle.value,
                description: outagecheck.noOutageElectricityAndNoGasText.value,
                button: href && text ? { href, text } : undefined,
              });
            } else if (
              req.data.electricityNetwork?.isHealthy &&
              !req.data.electricityNetwork?.isOperational &&
              req.data.gasNetwork?.isHealthy &&
              req.data.gasNetwork?.isOperational
            ) {
              const { href, text } = outagecheck.noOutageButton.value;
              // No outage, operational gas
              
              openDialog();

              return setDialogData({
                tag,
                title: outagecheck.noOutageTitle.value,
                description: outagecheck.noOutageNoElectricityAndGasText.value,
                button: href && text ? { href, text } : undefined,
              });
            } else if (
              req.data.electricityNetwork?.isOperational &&
              !req.data.electricityNetwork?.isHealthy &&
              req.data.gasNetwork?.isHealthy &&
              req.data.gasNetwork?.isOperational
            ) {
              const { href, text } = outagecheck.outageButton.value;
              // Outage electricity, operational electricity and gas

              if (redirectOutagesPage) {
                router.push({
                  pathname: redirectOutagesPage,
                  query: {
                    referentie: req.data.outageNumber,
                    postcode: postalCode,
                    plaats: req.data.address?.city?.toLowerCase(),
                  },
                });
              }

              return setDialogData({
                tag,
                title: outagecheck.outageTitle.value,
                description: outagecheck.outageElectricityAndGasText.value,
                button:
                  href && text
                    ? {
                        href: `${href}${
                          req.data.outageNumber ? `?referentienummer=${req.data.outageNumber}` : ''
                        }`,
                        text,
                      }
                    : undefined,
              });
            } else if (
              req.data.electricityNetwork?.isOperational &&
              !req.data.electricityNetwork?.isHealthy &&
              !req.data.gasNetwork?.isHealthy &&
              req.data.gasNetwork?.isOperational
            ) {
              const { href, text } = outagecheck.outageButton.value;
              // Outage gas and electricity, operational electricity and gas

              if (redirectOutagesPage) {
                router.push({
                  pathname: redirectOutagesPage,
                  query: {
                    referentie: req.data.outageNumber,
                    postcode: postalCode,
                    plaats: req.data.address?.city?.toLowerCase(),
                  },
                });
              }

              return setDialogData({
                tag,
                title: outagecheck.outageTitle.value,
                description: outagecheck.outageElectricityAndGasText.value,
                button:
                  href && text
                    ? {
                        href: `${href}${
                          req.data.outageNumber ? `?referentienummer=${req.data.outageNumber}` : ''
                        }`,
                        text,
                      }
                    : undefined,
              });
            } else if (
              req.data.electricityNetwork?.isOperational &&
              !req.data.electricityNetwork?.isHealthy &&
              !req.data.gasNetwork?.isOperational &&
              req.data.gasNetwork?.isHealthy
            ) {
              const { href, text } = outagecheck.outageButton.value;
              // Outage electricity, operational electricity

              if (redirectOutagesPage) {
                router.push({
                  pathname: redirectOutagesPage,
                  query: {
                    referentie: req.data.outageNumber,
                    postcode: postalCode,
                    plaats: req.data.address?.city?.toLowerCase(),
                  },
                });
              }

              return setDialogData({
                tag,
                title: outagecheck.outageTitle.value,
                description: outagecheck.outageElectricityAndNoGasText.value,
                button:
                  href && text
                    ? {
                        href: `${href}${
                          req.data.outageNumber ? `?referentienummer=${req.data.outageNumber}` : ''
                        }`,
                        text,
                      }
                    : undefined,
              });
            } else if (
              req.data.electricityNetwork?.isHealthy &&
              !req.data.electricityNetwork?.isOperational &&
              req.data.gasNetwork?.isOperational &&
              !req.data.gasNetwork?.isHealthy
            ) {
              const { href, text } = outagecheck.outageButton.value;
              // Outage gas, operational gas

              if (redirectOutagesPage) {
                router.push({
                  pathname: redirectOutagesPage,
                  query: {
                    referentie: req.data.outageNumber,
                    postcode: postalCode,
                    plaats: req.data.address?.city?.toLowerCase(),
                  },
                });
              }

              return setDialogData({
                tag,
                title: outagecheck.outageTitle.value,
                description: outagecheck.outageNoElectricityAndGasText.value,
                button:
                  href && text
                    ? {
                        href: `${href}${
                          req.data.outageNumber ? `?referentienummer=${req.data.outageNumber}` : ''
                        }`,
                        text,
                      }
                    : undefined,
              });
            } else if (
              req.data.electricityNetwork?.isHealthy &&
              req.data.electricityNetwork?.isOperational &&
              req.data.gasNetwork?.isOperational &&
              !req.data.gasNetwork?.isHealthy
            ) {
              const { href, text } = outagecheck.outageButton.value;
              // Outage gas, operational electricity and gas

              if (redirectOutagesPage) {
                router.push({
                  pathname: redirectOutagesPage,
                  query: {
                    referentie: req.data.outageNumber,
                    postcode: postalCode,
                    plaats: req.data.address?.city?.toLowerCase(),
                  },
                });
              }

              return setDialogData({
                tag,
                title: outagecheck.outageTitle.value,
                description: outagecheck.outageNoElectricityAndGasText.value,
                button:
                  href && text
                    ? {
                        href: `${href}${
                          req.data.outageNumber ? `?referentienummer=${req.data.outageNumber}` : ''
                        }`,
                        text,
                      }
                    : undefined,
              });
            }
          } catch (err) {
            trackPostalcodeCheckError({ error: (err as any).message });
          }
        }}
      />

      <DialogWithContent {...dialogData} />
    </>
  );
};

export type OutageCheckProps = {
  button: { value: string };
  huisnr: { value: string };
  postcode: { value: string };
  title: { value: string };
  noElectricityAndNoGasButton: {
    value: {
      href?: string;
      text?: string;
      linktype?: string;
      url?: string;
      anchor?: string;
      target?: string;
    };
  };
  noElectricityAndNoGasText: { value: string };
  noElectricityAndNoGasTitle: { value: string };
  noOutageButton: {
    value: {
      href?: string;
      text?: string;
      class?: string;
      id?: string;
      querystring?: string;
      anchor?: string;
      target?: string;
      title?: string;
      linktype?: string;
      url?: string;
    };
  };
  noOutageElectricityAndGasText: { value: string };
  noOutageElectricityAndNoGasText: { value: string };
  noOutageNoElectricityAndGasText: { value: string };
  noOutageTitle: { value: string };
  outageButton: {
    value: {
      href?: string;
      text?: string;
      linktype?: string;
      url?: string;
      anchor?: string;
      target?: string;
    };
  };
  outageElectricityAndGasText: { value: string };
  outageElectricityAndNoGasText: { value: string };
  outageNoElectricityAndGasText: { value: string };
  outageTitle: { value: string };
  tag: { value: string };
  noOutagesPage: { value: ButtonProps };
  outagePage: { value: ButtonProps };
};
