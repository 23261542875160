import React, { useRef } from 'react';
import clsx from 'clsx';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { useMedia } from '../../hooks/useMedia';
import { mq } from '../../global/breakpoints';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { ButtonLink } from '../ButtonLink';
import { ExternalIcon } from '../../icons/index';
import { LinkListProps, LinkListView } from '../LinkList';
import { DictionaryDialog } from '../DictionaryDialog';
import { RichText } from '../RichText';
import { CombineProps } from '../../types/type-helpers';
import * as styles from './SubpageHeroSideImage.css';

export const SubpageHeroSideImageComponent = (
  props: SubpageHeroSideImageProps
) => <SubpageHeroSideImageView {...props} />;

export const SubpageHeroSideImageSitecore = (
  props: SubpageHeroSideImageSitecoreProps
) => {
  return <SubpageHeroSideImageView {...props} />;
};

export const SubpageHeroSideImageView = (
  props: SubpageHeroSideImageViewProps
) => {
  const { text, explanation, linklist, sideImage, textLink } = props;
  const isDesktop = useMedia(mq.md);
  const elementRef = useRef<HTMLDivElement>(null);

  const scrollToElement = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return isDesktop ? (
    <PageGrid>
      <GridItem columnStart="1" columnEnd="span 5">
        <Stack direction="column" alignY="center" gap={8}>
          {text ? (
            <Heading size="h1" color="onLight">
              <DictionaryDialog>{text}</DictionaryDialog>
            </Heading>
          ) : null}
          {explanation ? (
            <Box width="100%" asChild>
              <Text as="div" align={{ initial: 'center', lg: 'start' }}>
                <RichText>{explanation}</RichText>
              </Text>
            </Box>
          ) : null}
          {textLink ? (
            <ButtonLink afterIcon={<ExternalIcon size="sm" />} asChild>
              {textLink}
            </ButtonLink>
          ) : null}
        </Stack>
      </GridItem>
      <GridItem
        columnStart="span 6"
        columnEnd="-1"
        className={styles.listWrapper}
      >
        <>
          <Box className={styles.imageWrapper} asChild>
            <Box
              width="100%"
              height="100%"
              borderRadius={{ lg: 'brandXl' }}
              className={styles.image}
              asChild
            >
              {sideImage}
            </Box>
          </Box>
          {linklist && linklist.length > 0 ? (
            <Box className={styles.linkList}>
              <LinkListView linklist={linklist} />
            </Box>
          ) : null}
        </>
      </GridItem>
      <GridItem columnStart="1" columnEnd="-1">
        <Stack
          isFullWidth
          direction="row"
          alignX="center"
          className={clsx(styles.arrowWrapper)}
        >
          <div
            className={styles.ArrowButton}
            onClick={scrollToElement}
            ref={elementRef}
          />
        </Stack>
      </GridItem>
    </PageGrid>
  ) : (
    <PageGrid>
      <GridItem columnStart="1" columnEnd="-1" asChild>
        <Stack alignY="center" gap={8}>
          <Stack direction="column" alignY="center" alignX="center" gap={8}>
              {text ? (
                <Heading size="h1" color="onLight">
                  <DictionaryDialog>{text}</DictionaryDialog>
                </Heading>
              ) : null}

              {explanation ? (
                <Box width="100%" asChild>
                  <Text as="div" align={{ initial: 'center', lg: 'start' }}>
                    <RichText>{explanation}</RichText>
                  </Text>
                </Box>
              ) : null}
              {textLink ? (
                <Stack alignX="center">
                  <ButtonLink afterIcon={<ExternalIcon size="sm" />} asChild>
                    {textLink}
                  </ButtonLink>
                </Stack>
              ) : null}
          </Stack>
        </Stack>
      </GridItem>

      <GridItem columnStart="1" columnEnd="-1" className={styles.listWrapper}>
        {sideImage ? (
          <Box className={styles.imageWrapper} width="100vw" height="100%">
            <Box asChild className={styles.image} height="100%" width="100%">
              {sideImage}
            </Box>
          </Box>
        ) : null}
        {linklist ? (
          <Box className={styles.linkList}>
            <LinkListView linklist={linklist} />
          </Box>
        ) : null}
      </GridItem>
    </PageGrid>
  );
};

type SubpageHeroSideImageProps = {
  text?: string;
  explanation?: string;
  sideImage?: React.ReactElement;
  textLink?: React.ReactElement;
  linklist?: LinkListProps['linklist'];
};

export type SubpageHeroSideImageSitecoreProps = {
  sideImage?: React.ReactElement;
  explanation?: string;
  text?: React.ReactElement | string;
  textLink?: React.ReactElement;
  linklist?: LinkListProps['linklist'];
};

type SubpageHeroSideImageViewProps = CombineProps<
  SubpageHeroSideImageProps,
  SubpageHeroSideImageSitecoreProps
>;
