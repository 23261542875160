import { ParagraphSitecore, RichText, ScrollLinkWrapper } from '@ads-core/components';
import { SitecoreRichText, renderJssLink } from '@alliander-fe/jss-utils';
import { RichTextField, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';
import React from 'react';

type ParagraphPropsJssProps = ComponentProps & {
  fields: {
    text: RichTextField;
    primaryButton: LinkField;
    secondaryButton: LinkField;
  };
  params?: {
    anchor?: string;
  };
};

const Paragraph = (props: ParagraphPropsJssProps) => {
  const isEditor = !!props.fields.text.editable;

  const text = isEditor ? (
    <SitecoreRichText field={props.fields.text} editable />
  ) : (
    <RichText>{props.fields.text.value ?? undefined}</RichText>
  );

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <ParagraphSitecore
        text={text}
        primaryButton={renderJssLink(props.fields.primaryButton)}
        secondaryButton={renderJssLink(props.fields.secondaryButton)}
      />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(Paragraph);
