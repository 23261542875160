import React from 'react';
import { useRouter } from 'next/router';

import { baseQueryParams, forceString } from 'src/utils';
import { getOutagesOnLocation, isErrorResponse } from 'src/api';

import { IntegrationApiFeaturesOutagesContractsDataOutage as OutageProps } from '@alliander-fe/api/data-contracts';

import {
  ListHeroView,
  Stack,
  TextLink,
  Box,
  Dialog,
  StackItem,
  ScrollLinkWrapper,
  Pagination,
} from '@ads-core/components';
import { ChecklistIcon, ChevronRightIcon, PhoneAndroidIcon } from '@ads-core/icons';
import { useDialogContext, useMedia } from '@ads-core/hooks';
import { mq } from '@ads-core/breakpoints';

import { SitecoreImage } from '@alliander-fe/jss-utils';
import { setReplaceText } from '@alliander-fe/validation';

import { OutagesOnLocationProps } from 'components/OutagesOnLocation';
import { capitalizeLocationName } from 'src/utils/format';

import { getOutageData } from '../OutageHero';
import { OutageDialogContent, OutageDialogContentProps } from '../OutageModal';
import OutageBlock from '../OutageBlock';

type OutagesProps = {
  location: string;
  amountOfOutages?: number;
  outages?: OutageProps[];
};


type FetchStatus = 'initial' | 'loading' | 'done' | 'error' | 'notFound';

export const queryParams = baseQueryParams;

export const LocationOutages = ({
  outageDetailsModalText,
  outageDetailsModalLinkOne,
  outageDetailsModalLinkTwo,
  ...props
}: OutagesOnLocationProps['fields']) => {
  const { isOpen, openDialog } = useDialogContext();
  const limit = 10;
  const router = useRouter();
  const [outages, setOutages] = React.useState<OutagesProps>();
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial');
  const [outageDialogData, setOutageDialogData] = React.useState<OutageDialogContentProps>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const allResolvedOutages = props?.onlyShowAllResolvedOutages?.value;
  const resolvedOutages = props?.onlyShowRecentlyResolvedOutages?.value;

  const getOutages = React.useCallback(
    async (location?: string) => {
      if (!location) return;

      setFetchStatus('loading');
      const request = await getOutagesOnLocation(
        location,
        resolvedOutages || allResolvedOutages,
        resolvedOutages ? 2 : undefined
      );

      if (isErrorResponse(request)) {
        setFetchStatus('error');
        return;
      }

      setOutages({
        location: capitalizeLocationName(location),
        amountOfOutages: request.outages?.length,
        outages: request.outages ? request.outages : undefined,
      });

      setFetchStatus('done');
    },
    [allResolvedOutages, resolvedOutages]
  );

  const handleOpenDialog = async (props: OutageProps) => {
    if (!resolvedOutages && props.referenceNumber) {
      const outageModalData = await getOutageData(props.referenceNumber.toString());

      if (outageModalData && outageModalData.referenceNumber) {
        setOutageDialogData({
          title: outages?.location ? outages.location : '',
          statusDescription: '',
          postalCode: '',
          city: outages?.location ? outages.location : '',
          ...outageModalData,
          affectedStreets: props.affectedStreets
            ? setReplaceText(props.affectedStreets, { ';': ', ' })
            : '',
          affectedPostalCodes: props.affectedPostalCodes
            ? setReplaceText(props.affectedPostalCodes, { ';': ', ' })
            : '',
          outageDetailsText: outageDetailsModalText,
          outageDetailsLinkOne: outageDetailsModalLinkOne,
          outageDetailsLinkTwo: outageDetailsModalLinkTwo,
        });

        openDialog();
        return;
      }
    }

    setOutageDialogData({
      title: outages?.location ? outages.location : '',
      city: outages?.location ? outages.location : '',
      ...props,
      affectedStreets: props.affectedStreets
        ? setReplaceText(props.affectedStreets, { ';': ', ' })
        : '',
      affectedPostalCodes: props.affectedPostalCodes
        ? setReplaceText(props.affectedPostalCodes, { ';': ', ' })
        : '',
      outageDetailsText: outageDetailsModalText,
      outageDetailsLinkOne: outageDetailsModalLinkOne,
      outageDetailsLinkTwo: outageDetailsModalLinkTwo,
    });

    openDialog();
  };

  React.useEffect(() => {
    const path = router.query.path;
    const locationQuery = forceString(router.query.plaats);
    let locationPath: string | undefined;

    if (typeof path === 'object') {
      locationPath = path[path.length - 1];
    }

    const location = locationQuery || locationPath;

    if (location && !outages?.location) {
      getOutages(locationQuery.length ? locationQuery : locationPath);
    }
  }, [router, getOutages, outages?.location]);

  React.useEffect(() => {
    const currentPageQuery = forceString(router.query.pagina);
    const pageNumber = Number(currentPageQuery) - 1;
    const validPage = currentPageQuery !== '' && !isNaN(Number(pageNumber));

    const amountOfOutages = outages?.amountOfOutages ? outages?.amountOfOutages : 0;
    const amountOfPages = Math.floor(amountOfOutages / limit);

    if (!validPage) return;

    if (pageNumber <= amountOfPages) {
      setCurrentPage(pageNumber >= 0 ? pageNumber : 0);
      return;
    }

    setCurrentPage(amountOfPages);
  }, [outages, router]);

  React.useEffect(() => {
    if (!isOpen) {
      setOutageDialogData(undefined);
    }
  }, [isOpen]);

  if (
    resolvedOutages &&
    (!outages?.outages || outages.outages.length === 0)
  ) {
    return <></>
  }

  return (
    <>
      {outageDialogData ? (
        <Dialog>
          <OutageDialogContent {...outageDialogData} resolvedOutages={resolvedOutages || allResolvedOutages} />
        </Dialog>
      ) : null}
        <ScrollLinkWrapper
        anchorId={resolvedOutages || allResolvedOutages ? 'opgelosteonderbrekingen' : 'actueleonderbrekingen'}
      >
      <ListHeroView
        image={<SitecoreImage field={props.image} editable />}
        variant={resolvedOutages ? 'contained' : 'breakout'}
        text={
          fetchStatus !== 'initial' && fetchStatus !== 'loading'
            ? outagesTitle(
                outages?.amountOfOutages,
                resolvedOutages || allResolvedOutages ? props?.title?.value : undefined,
                outages?.location
              )
            : undefined
        }
        list={
          fetchStatus !== 'initial' && fetchStatus !== 'loading' && outages?.outages ? (
            <Box width="100%" overflowY="hidden" asChild>
              <Stack
                direction={resolvedOutages ? { initial: 'row', md: 'column' } : 'column'}
                gap={4}
                isFullWidth
              >
                {outages.outages
                  .slice(currentPage * limit, (currentPage + 1) * limit)
                  .map((outage, index) => (
                    <Box
                      width={resolvedOutages ? { initial: '90%', sm: '50%', md: '100%' } : '100%'}
                      key={index}
                      asChild
                    >
                      <StackItem shrink={false}>
                        <OutageBlock.Container>
                          {resolvedOutages ? (
                            <OutageBlock.Resolved
                              {...outage}
                              handleToggleDialog={(props) => handleOpenDialog(props)}
                            />
                          ) : null}

                          {!resolvedOutages && allResolvedOutages ? (
                            <OutageBlock.AllResolved
                              {...outage}
                              handleToggleDialog={(props) => handleOpenDialog(props)}
                            />
                          ) : null}

                          {!resolvedOutages && !allResolvedOutages ? (
                            <OutageBlock.Current
                              {...outage}
                              resolvedOutages={resolvedOutages || allResolvedOutages}
                              handleToggleDialog={(props) => handleOpenDialog(props)}
                            />
                          ) : null}
                        </OutageBlock.Container>
                      </StackItem>
                    </Box>
                  ))}
              </Stack>
            </Box>
          ) : null
        }
        explanation={
          fetchStatus !== 'initial' && fetchStatus !== 'loading' ? (
            <Stack gap={2} alignY="end">
              <ExplanationLinks
                resolvedOutages={resolvedOutages}
                hasOutages={
                  typeof outages?.amountOfOutages === 'number' && outages?.amountOfOutages > 0
                }
                outageData={props}
              />
            </Stack>
          ) : undefined
        }
      />
      </ScrollLinkWrapper>
      {outages ? (
        <Pagination
          forcePage={currentPage}
          pageCount={outages.amountOfOutages ? outages.amountOfOutages / limit : 0}
          onPageChange={(e) => {
            const selectedPage = e.selected + 1;
            router.push({
              pathname: router.pathname,
              query: {
                ...router.query,
                [queryParams.page]: selectedPage,
              },
            });
          }}
        />
      ) : null}
    </>
  );
};

const ExplanationLinks = ({
  resolvedOutages,
  outageData,
  hasOutages,
}: {
  resolvedOutages?: boolean;
  hasOutages: boolean;
  outageData: OutagesOnLocationProps['fields'];
}) => {
  const isDesktop = useMedia(mq.md);

  if (!hasOutages) {
    return (
      <>
        {outageData.noOutagesLinkOne ? (
          <TextLink
            href={outageData.noOutagesLinkOne.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData.noOutagesLinkOne?.value?.text}
          </TextLink>
        ) : null}
        {outageData.noOutagesLinkTwo ? (
          <TextLink
            href={outageData.noOutagesLinkTwo.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData.noOutagesLinkTwo.value?.text}
          </TextLink>
        ) : null}
      </>
    );
  }

  if (resolvedOutages && isDesktop) {
    return (
      <>
        {outageData.linkOne ? (
          <TextLink
            href={outageData?.linkOne?.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData?.linkOne?.value?.text}
          </TextLink>
        ) : null}
        {outageData.linkTwo ? (
          <TextLink
            href={outageData?.linkTwo?.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData?.linkTwo?.value?.text}
          </TextLink>
        ) : null}
      </>
    );
  }

  return (
    <>
      {outageData.linkOne ? (
        <TextLink
          href={outageData?.linkOne?.value?.href}
          beforeIcon={
            !resolvedOutages ? <PhoneAndroidIcon size="sm" /> : <ChevronRightIcon size="sm" />
          }
        >
          {outageData?.linkOne?.value?.text}
        </TextLink>
      ) : null}
      {outageData.linkTwo ? (
        <TextLink
          href={outageData?.linkTwo?.value?.href}
          beforeIcon={
            !resolvedOutages ? <ChecklistIcon size="sm" /> : <ChevronRightIcon size="sm" />
          }
        >
          {outageData?.linkTwo?.value?.text}
        </TextLink>
      ) : null}
    </>
  );
};

// Create the replace text for the outages based on the maintenance and outages.
const outagesTitle = (
  outages: number | undefined,
  resolvedTitle: string | undefined,
  location: string | undefined
): string => {
  if (resolvedTitle)
    return setReplaceText(resolvedTitle, { '{location}': location ? location : '' });

  switch (outages) {
    case 1:
      return `Er is ${outages} stroom- of gasonderbreking in ${location}`;
    case 0:
      return `Er zijn geen stroom- of gasonderbrekingen in ${location}`;
    default:
      return `Er zijn ${outages} stroom- en/of gasonderbrekingen in ${location}`;
  }
};