import React from 'react';
import {
  Box,
  GridItem,
  Heading,
  PageGrid,
  Text,
  Stack,
  StackItem,
  Button,
  InputText,
  InputTelephone,
  Checkbox,
  TextLink,
  ScrollLinkWrapper,
  RichText,
} from '@ads-core/components';
import {
  FastEndpointsErrorResponse as ErrorResponse,
  IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityResponse as ServiceAvailabilityResponse,
  IntegrationApiFeaturesOutagesContractsResponsesSubscribeSmsServiceResponse as ResponsesSubscribeSmsServiceResponse,
} from '@ads-core/styles/api/data-contracts';
import useMedia from 'react-use/lib/useMedia';
import { mq } from '@ads-core/breakpoints';
import { SitecoreImage } from '@alliander-fe/jss-utils';
import { OutagesSMSServiceProps } from 'components/OutagesSMSService';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useRouter } from 'next/router';
import { forceString } from 'src/utils';
import { isErrorResponse, getOutagesOnLocation } from 'src/api';

import { houseNumberPattern, postalCodePattern, telephonePattern } from '@ads-core/utils';
import { TelephoneNumberMasking, applyC4Rules } from '../Form/utils/utils';

import * as styles from './OutageSMSService.css';
type Inputs = {
  houseNumber: number;
  houseNumberAddition: string;
  postalCode: string;
  telephoneNumber: string;
  save: boolean;
};

type FetchStatus = 'initial' | 'loading' | 'done' | 'error' | 'notFound';

export const OutagesSMSServiceView = (props: OutagesSMSServiceProps['fields']) => {
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial');
  const resolvedOutages = false;
  const [toggleService, setToggleService] = React.useState<boolean>(true);
  const isDesktop = useMedia(mq.md, true);
  const router = useRouter();

  const methods = useForm({
    mode: 'onTouched',
  });

  const getOutages = async (location?: string) => {
    if (!location) return;

    const request = await getOutagesOnLocation(location, resolvedOutages, 2);

    if (isErrorResponse(request)) {
      return;
    }

    setToggleService(request.outages?.length ? true : false);
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setFetchStatus('loading');

    if (data.save) {
      // Sets the correct format for postalCode and telephoneNumber.
      const postalCode = data.postalCode.replace(/\s+/g, '');
      const telephoneNumber = data.telephoneNumber.replace('+31 6', '06');

      const responseService = await apiGetServiceAvailability({
        postalCode: postalCode,
        houseNumber: data.houseNumber,
        houseNumberAddition: data.houseNumberAddition,
      });

      if (isErrorResponse(responseService) || !responseService.isOperational) {
        setFetchStatus('notFound');
        return;
      }

      const response = await apiSubscribeSMSService({
        ...data,
        postalCode: postalCode,
        telephoneNumber: telephoneNumber,
      });

      if (isErrorResponse(response)) {
        setFetchStatus('error');

        return;
      }

      if (response.success) {
        setFetchStatus('done');

        return;
      }
    }

    setFetchStatus('error');
  };

  React.useEffect(() => {
    const path = router.query.path;
    const locationQuery = forceString(router.query.plaats);
    let locationPath: string | undefined;

    if (typeof path === 'object') {
      locationPath = path[path.length - 1];
    }

    if ((locationPath || locationQuery) && props.disableIfThereAreNoOutagesOnLocation?.value) {
      getOutages(locationQuery.length ? locationQuery : locationPath);
    }
  }, [router.query.path]);

  if (!toggleService) {
    return null;
  }

  const Notification = () => {
    switch (fetchStatus) {
      case 'error':
        return (
          <>
            <Box paddingBottom={4} asChild>
              <Heading size="h5" as="h3">
                {props?.errorMessageTitle?.value}
              </Heading>
            </Box>
            <Text>{props?.errorMessageText?.value}</Text>
          </>
        );
      case 'notFound':
        return (
          <>
            We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of
            zoek uw netbeheerder op via{' '}
            <TextLink href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">
              mijnaansluiting.nl
            </TextLink>
            .
          </>
        );
      case 'done':
        return (
          <>
            <Box paddingBottom={4} asChild>
              <Heading size="h5" as="h3">
                {props?.successMessageTitle?.value}
              </Heading>
            </Box>
            <RichText>{props?.successMessageText?.value}</RichText>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <ScrollLinkWrapper anchorId="smsdienst">
      <PageGrid>
        <GridItem columnEnd="-1" columnStart="1">
          <Box bg="containerPrimary" width="100%" borderRadius="brandXl" overflow="hidden" asChild>
            <Stack direction={{ initial: 'column', md: 'row' }} gap={4}>
              <StackItem grow asChild>
                <Box
                  width={{ initial: '100%', md: '60%' }}
                  paddingTop={{ initial: 10, md: 20 }}
                  paddingBottom={{ initial: 5, md: 20 }}
                  paddingInline={{ initial: 8, md: 20 }}
                >
                  <Box paddingBottom={{ initial: 3, md: 4 }} asChild>
                    <Heading size="h2" color="onDark">
                      {props?.title?.value}
                    </Heading>
                  </Box>
                  <RichText tone="onDark">{props?.text?.value}</RichText>

                  <Box paddingTop={{ initial: 6, md: 10 }}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <PageGrid style={{ '--gutter': 0 }}>
                        <GridItem columnStart="1" columnEnd={{ initial: '-1', md: '7' }} asChild>
                          <Stack isFullWidth asChild>
                            <Box paddingBottom={{ initial: 2, md: 0 }}>
                              <InputText
                                label="Postcode"
                                tone="onDark"
                                error={
                                  methods?.formState?.errors?.postalCode?.message as
                                    | string
                                    | undefined
                                }
                                {...methods.register('postalCode', {
                                  required: {
                                    value: true,
                                    message: 'Dit veld is verplicht',
                                  },
                                  pattern: postalCodePattern,
                                })}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem
                          columnStart={{ initial: '1', md: '7' }}
                          columnEnd={{ initial: '3', sm: '7', md: '10' }}
                          asChild
                        >
                          <Stack isFullWidth asChild>
                            <Box paddingBottom={{ initial: 2, md: 0 }}>
                              <InputText
                                label="Huisnummer"
                                tone="onDark"
                                inputMode="numeric"
                                error={
                                  methods?.formState?.errors?.houseNumber?.message as
                                    | string
                                    | undefined
                                }
                                {...methods.register('houseNumber', {
                                  required: {
                                    value: true,
                                    message: 'Dit veld is verplicht',
                                  },
                                  pattern: houseNumberPattern,
                                })}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem
                          columnStart={{ initial: '3', sm: '7', md: '10' }}
                          columnEnd="-1"
                          asChild
                        >
                          <Stack isFullWidth>
                            <Box paddingBottom={{ initial: 2, md: 0 }}>
                              <InputText
                                label="Toevoeging"
                                tone="onDark"
                                error={
                                  methods?.formState?.errors?.houseNumberAddition?.message as
                                    | string
                                    | undefined
                                }
                                {...methods.register('houseNumberAddition')}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem columnStart="1" columnEnd="-1" asChild>
                          <Stack isFullWidth>
                            <Box paddingBottom={{ initial: 4, md: 0 }}>
                              <InputTelephone
                                label="Telefoonnummer"
                                tone="onDark"
                                error={
                                  methods?.formState?.errors?.telefoonnummer?.message as
                                    | string
                                    | undefined
                                }
                                handlePhoneMasking={TelephoneNumberMasking}
                                onValueChange={(value, countryCode) =>
                                  methods.setValue(
                                    'telephoneNumber',
                                    applyC4Rules(value, countryCode)
                                  )
                                }
                                {...methods.register('telephoneNumber', {
                                  required: {
                                    value: true,
                                    message: 'Dit veld is verplicht',
                                  },
                                  pattern: telephonePattern,
                                })}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem columnStart="1" columnEnd="-1" asChild>
                          <Stack isFullWidth>
                            <Box paddingBottom={{ initial: 4, md: 0 }}>
                              <Checkbox
                                label="Bewaar mijn gegevens voor toekomstige storingen"
                                tone="onDark"
                                onCheckedChange={(checked) => methods.setValue('save', checked)}
                                error={
                                  methods?.formState?.errors?.save?.message as string | undefined
                                }
                                {...methods.register('save', {
                                  required: { value: true, message: 'Dit veld is verplicht' },
                                  validate: {
                                    validationModel: (v) => {
                                      if (typeof v === 'boolean') {
                                        return v;
                                      }

                                      return 'Dit veld is verplicht';
                                    },
                                  },
                                })}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem>
                          <Stack isFullWidth={{ initial: true, md: false }}>
                            <Button
                              type="submit"
                              variant="secondary"
                              tone="onDark"
                              size="large"
                              isLoading={fetchStatus === 'loading'}
                            >
                              Ontvang meldingen via SMS
                            </Button>
                          </Stack>
                        </GridItem>
                      </PageGrid>
                    </form>
                  </Box>
                </Box>
              </StackItem>

              {isDesktop ? (
                <StackItem grow asChild>
                  <Box width="40%" height="100%" position="relative">
                    <Box
                      bg={Notification() ? 'backgroundBackdrop' : undefined}
                      position="absolute"
                      padding={20}
                      zIndex="1"
                      asChild
                    >
                      <Stack isFullHeight isFullWidth alignX="center" alignY="center">
                        <Box
                          bg={Notification() ? 'backgroundLight' : undefined}
                          padding={10}
                          borderRadius="brandXl"
                        >
                          {Notification()}
                        </Box>
                      </Stack>
                    </Box>
                    <Box className={styles.image} asChild>
                      <SitecoreImage field={props.image} editable />
                    </Box>
                  </Box>
                </StackItem>
              ) : (
                <StackItem grow asChild>
                  {Notification() ? (
                    <Box bg={Notification() ? 'backgroundBackdrop' : undefined} zIndex="1" asChild>
                      <Stack isFullHeight isFullWidth alignX="center" alignY="center">
                        <Box bg={Notification() ? 'backgroundLight' : undefined} padding={10}>
                          {Notification()}
                        </Box>
                      </Stack>
                    </Box>
                  ) : (
                    <Box className={styles.image} asChild>
                      <SitecoreImage field={props.image} editable />
                    </Box>
                  )}
                </StackItem>
              )}
            </Stack>
          </Box>
        </GridItem>
      </PageGrid>
    </ScrollLinkWrapper>
  );
};

type ApiSubscribeSMSServiceProps = {
  houseNumber: number;
  houseNumberAddition: string;
  postalCode: string;
  telephoneNumber: string;
};

// SubScribe SMS Service Post request
const apiSubscribeSMSService = async (
  props: ApiSubscribeSMSServiceProps
): Promise<ResponsesSubscribeSmsServiceResponse | ErrorResponse> => {
  try {
    const uri = `/api/outages/subscribe/sms-service`;
    const response = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        houseNumber: props.houseNumber,
        houseNumberAddition: props.houseNumberAddition,
        postalCode: props.postalCode,
        telephoneNumber: props.telephoneNumber,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw await response.json();
    }
  } catch (e) {
    if (isErrorResponse(e)) {
      return e;
    }

    console.error('Rest api call for subscribe sms service failed:', e);

    return {
      errors: {
        api: [`Rest api call for subscribe sms service failed: ${e} `],
      },
    };
  }
};

type ApiGetServiceAvailability = {
  postalCode: string;
  houseNumber: number;
  houseNumberAddition?: string;
};

// PostalCode Check request
const apiGetServiceAvailability = async (
  props: ApiGetServiceAvailability
): Promise<ServiceAvailabilityResponse | ErrorResponse> => {
  try {
    let request = `${props.postalCode}/${props.houseNumber.toString()}`;

    if (props.houseNumberAddition) {
      request = `${request}/${props.houseNumberAddition}`;
    }

    const uri = `/api/service-availability/${request}`;
    const response = await fetch(uri);

    if (response.ok) {
      return await response.json();
    } else {
      throw await response.json();
    }
  } catch (e) {
    if (isErrorResponse(e)) {
      return e;
    }

    console.error('Rest api call for service availability failed:', e);

    return {
      errors: {
        api: [`Rest api call for service availability failed: ${e} `],
      },
    };
  }
};
