import React from 'react';
import {
  GridItem,
  PageGrid,
  Box,
  Heading,
  Stack,
  Loader,
  TextLink,
  Text,
  Columns,
  Divider,
  ScrollLinkWrapper,
  Alert,
} from '@ads-core/components';
import { IntegrationApiFeaturesOutagesContractsDataLocationInfo as LocationInfo } from '@ads-core/styles/api/data-contracts';
import { OutagesOverviewProps } from 'components/OutagesOverview';
import { setReplaceText } from 'src/utils';
import { capitalizeLocationName } from 'src/utils/format';

import { mq } from '@ads-core/breakpoints';
import useMedia from 'react-use/lib/useMedia';

import * as styles from './OutagesOverview.css';

type FetchStatus = 'initial' | 'loading' | 'done' | 'error' | 'notFound';

type Outages = {
  amountOfOutages?: number;
  locations?: LocationInfo[];
};

export const OutagesOverviewView = (props: OutagesOverviewProps['fields']) => {
  const { onlyShowResolvedOutages } = props;
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial');
  const [outages, setOutages] = React.useState<Outages>();
  const isTablet = useMedia(mq.sm);


  const getOutagesOverviewData = async (resolvedOutages?: boolean) => {
    setFetchStatus('loading');
    let request

    if(resolvedOutages) {
      request = await getOutagesResolvedLocations();
    }

    if(!resolvedOutages) {
      request = await getOutagesUnresolvedLocations();
    }

    if (!request || !request.locations) {
      setFetchStatus('notFound');
      return;
    }

    if (request.errorMessage) {
      setFetchStatus('error');
      return;
    }

    let amountOfOutages = 0;

    request.locations.forEach((location) => {
      if (location.amountOfOutages) {
        amountOfOutages = amountOfOutages + location.amountOfOutages;
      }
    });

    setOutages({
      amountOfOutages: amountOfOutages,
      locations: request.locations,
    });
    setFetchStatus('done');
  };


  React.useEffect(() => {
    getOutagesOverviewData(onlyShowResolvedOutages?.value);
  }, [onlyShowResolvedOutages]);

  return (
    <ScrollLinkWrapper anchorId="onderbrekingenoverzicht">
      <PageGrid>
        <GridItem
          columnStart={{ initial: '1', md: '3' }}
          columnEnd={{ initial: '-1', md: '-3' }}
          asChild
        >
          <Alert role='alert' id={'outages-overview'}>
          {fetchStatus === 'error' || fetchStatus === 'notFound' 
            ? `Er is iets fout gegaan en we kunnen momenteel de ${onlyShowResolvedOutages?.value ? 'opgeloste storingen' : 'storingen'} niet ophalen. Vernieuw de pagina om het opnieuw te proberen of neem contact met ons op.`
            : null}
          </Alert>
        </GridItem>
      </PageGrid>

      {fetchStatus !== 'error' && fetchStatus !== 'notFound' ? (
        <PageGrid>
          <GridItem
            columnStart={{ initial: '1', md: '3' }}
            columnEnd={{ initial: '-1', md: '-3' }}
            asChild
          >
            <Box paddingBottom={{ initial: 2, md: 8 }} asChild>
              <Heading size="h2">
                {setReplaceText(props.title.value, {
                  '{amountOfOutages}': outages?.amountOfOutages
                    ? outages.amountOfOutages.toString()
                    : '0',
                })}
              </Heading>
            </Box>
          </GridItem>
          <GridItem columnStart="1" columnEnd="-1" asChild>
            <Box
              paddingInline={{ initial: 6, md: 16 }}
              paddingTop={{ initial: 8, md: 16 }}
              paddingBottom={{ initial: 4, md: 10 }}
              borderRadius={'brandXl'}
              bg="containerLight"
            >
              {fetchStatus === 'initial' || fetchStatus === 'loading' ? (
                <Box paddingBottom={{ initial: 4, md: 10 }} asChild>
                  <Stack alignX="center" alignY="center" isFullHeight isFullWidth>
                    <Loader />
                  </Stack>
                </Box>
              ) : (
                <Box width="100%">
                  <Columns columns={{ initial: 1, sm: 2, lg: 3 }} gap={16}>
                    {outages?.locations?.map((outage, index) =>
                      outage.locationName ? (
                        <Box
                          paddingBottom={{ initial: 4, sm: 6 }}
                          overflow="hidden"
                          key={`${index}-${outage.locationName}-${outage.amountOfOutages}`}
                          asChild
                        >
                          <Stack>
                            <Box position="relative" paddingBottom={{ initial: 0, sm: 1 }} asChild>
                              <Stack direction="row" alignX="justify" alignY="end" isFullWidth>
                                <TextLink
                                  href={`${
                                    props.parentOfOutageLocationWildcardPage.value.href
                                  }/${capitalizeLocationName(outage.locationName)}`}
                                  className={styles.outageOverviewItemLink}
                                >
                                  {capitalizeLocationName(outage.locationName)}
                                </TextLink>
                                <Text size="label">

                                  {amountOfOutages({outages: outage.amountOfOutages, resolvedOutages: onlyShowResolvedOutages?.value})}
                                </Text>
                              </Stack>
                            </Box>
                            {isTablet ? <Divider tone="onLight" /> : null}
                          </Stack>
                        </Box>
                      ) : null
                    )}
                  </Columns>
                </Box>
              )}
            </Box>
          </GridItem>
        </PageGrid>
      ): null}
    </ScrollLinkWrapper>
  );
};

// Set the correct text based on the resolvedOutages boolean
const amountOfOutages = (
  {
    outages,
    resolvedOutages
  }:
  {
    outages?: number,
    resolvedOutages?: boolean}
): string => {
  if(resolvedOutages) {
    return `(${outages} opgelost)`
  }

  return outages === 1
  ? `(${outages} onderbreking)`
  : `(${outages} onderbrekingen)`
}

type LocationInfoResponse = {
  success?: boolean;
  errorMessage?: string;
  locations?: LocationInfo[];
};

// Get the unresolved Outages data form the API
const getOutagesUnresolvedLocations = async (): Promise<LocationInfoResponse> => {
  try {
    const uri = `/api/outages/unresolved/locations`;
    const response = await fetch(uri);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(response.statusText);
    }
  } catch (e) {
    console.error('Rest api call for congestions failed:', e);

    return {
      success: false,
      errorMessage: 'Rest api call for congestions failed',
    };
  }
};

// Get the resolved Outages data form the API
const getOutagesResolvedLocations = async (): Promise<LocationInfoResponse> => {
  try {
    const uri = `/api/outages/resolved/locations`;
    const response = await fetch(uri);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(response.statusText);
    }
  } catch (e) {
    console.error('Rest api call for congestions failed:', e);

    return {
      success: false,
      errorMessage: 'Rest api call for congestions failed',
    };
  }
};
