import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { ButtonProps as SiteCoreButtonProps } from '@alliander-fe/sitecore-types';
import { useEffect } from 'react';
import { ConclusionBlockProps, FollowUpStepProps } from '@ads-core/types';
import {
  FollowUpSteps,
  Stack,
  CongestionMarker,
  Box,
  ConclusionBlock,
  PageGrid,
  GridItem,
  Heading,
  ButtonLink,
  Loader,
  Alert,
  TextLink,
  Text,
  Button,
} from '@ads-core/components';
import { ButtonProps } from '@ads-core/types';
import { scrollToElement } from '@ads-core/utils';
import { useAdsContext } from '@ads-core/providers';
import { CongestionCheckerProps } from 'components/CongestionChecker';
import { forceString } from 'src/utils';
import {
  congestion,
  getCongestionData,
  getFeedbackAndConclusion,
  getLatestEndDate,
  getQuarterlyEndDate,
  hasCongestions,
  removeDuplicateData,
  setShortageData,
} from './utils';

export const CongestionCheck = (props: CongestionCheckerProps['fields']) => {
  const router = useRouter();
  const ref = React.useRef<HTMLDivElement>(null);
  const refCongestion = React.useRef<HTMLDivElement>(null);
  const refConclusion = React.useRef<HTMLDivElement>(null);
  const [congestionData, setCongestionData] = React.useState<CongestionDataProps>();
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial');
  const adsContext = useAdsContext();

  const getDataByPostalCode = useCallback(
    async (postalCode: string) => {
      setFetchStatus('loading');
      setCongestionData(undefined);

      const requestPostalCode = postalCode.replaceAll(' ', '').toUpperCase();
      const displayPostalCode =
        requestPostalCode.substring(0, 4) + ' ' + requestPostalCode.substring(4);
      const response = await getCongestionData(requestPostalCode);

      // Sets a error when something wen't wrong
      if (response.errorMessage) {
        setFetchStatus('error');
        return;
      }

      const consume = response.directions?.consume;
      const supply = response.directions?.supply;

      // if no data is found returns fetchStatus
      if (!consume && !supply) {
        setFetchStatus('notFound');
        return;
      }

      // Maps the data so we can use it for our component
      const shortageData = setShortageData(consume, supply);
      const congestionData = removeDuplicateData(shortageData);
      const congestions = hasCongestions(response);

      // Create the step data
      const followUpSteps = setCongestionFollowUpSteps(congestionData);

      const latestEndDate = getLatestEndDate(congestionData)

      // Get the feedback and conclusion text
      const feedbackAndConclusion = getFeedbackAndConclusion({
        props: props,
        replaceText: {
          '{postalCode}': displayPostalCode,
          '{congestionAmount}': `${
            followUpSteps.length === 1
              ? `${followUpSteps.length} knelpunt`
              : `${followUpSteps.length} knelpunten`
          }`,
          '{latestEndDate}': latestEndDate.latestEndDate,
          '{latestEndDateConsume}': latestEndDate.latestEndDateConsume,
          '{latestEndDateSupply}': latestEndDate.latestEndDateSupply
        },
        ...congestions,
      });

      setCongestionData({
        congestionData: followUpSteps,
        congestions: congestions,
        ...feedbackAndConclusion,
      });

      setFetchStatus('done');
      return;
    },
    [props]
  );

  useEffect(() => {
    setCongestionData(undefined);
    const postalCodeQuery = forceString(router.query.postalCode);

    if (postalCodeQuery) {
      getDataByPostalCode(postalCodeQuery);
    }
  }, [router, getDataByPostalCode]);

  useEffect(() => {
    if (fetchStatus !== 'initial' && fetchStatus !== 'loading' && ref.current) {
      scrollToElement(ref, adsContext.headerHeight);
    }
  }, [adsContext.headerHeight, fetchStatus]);

  const setNotification = () => {
    switch (fetchStatus) {
      case 'error':
        return <>Er is iets fout gegaan. Probeer het opnieuw of vul een andere postcode in.</>;
      case 'notFound':
        return (
          <>
            We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of
            zoek uw netbeheerder op via{' '}
            <TextLink href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">
              mijnaansluiting.nl
            </TextLink>
            .
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box ref={ref}>
      <PageGrid>
        <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
          <Alert variant={fetchStatus && fetchStatus === 'error' ? 'error' : 'info'}>
            {setNotification()}
          </Alert>
        </GridItem>
      </PageGrid>
      {fetchStatus === 'loading' ? (
        <PageGrid>
          <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
            <Heading size="h2">
              {' '}
              Laden... <Loader />
            </Heading>
          </GridItem>
        </PageGrid>
      ) : null}
      {congestionData?.feedback ? (
        <PageGrid>
          <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
            <Box paddingBottom={16} asChild>
              <Stack gap={6}>
                {congestionData.congestions ? (
                  <Stack direction="row" alignY="center" gap={6}>
                    <CongestionMarker
                      titel="Afnemen"
                      congestion={congestionData.congestions.consume}
                    />
                    <CongestionMarker
                      titel="Terugleveren"
                      congestion={congestionData.congestions.supply}
                    />
                  </Stack>
                ) : null}

                <Heading size="h3" as="h2">
                  {congestionData.feedback.description}
                </Heading>

                {props?.congestionText?.value && congestionData?.congestionData?.length ? (
                  <Text>{props.congestionText.value}</Text>
                ) : null}

                {congestionData.feedback.buttonPrimary || congestionData.feedback.buttonSecondary ? (
                  <Stack direction={{ initial: 'column', sm: 'row' }} gap={6}>
                    {congestionData.feedback.buttonPrimary ? (
                      <CongestionButton
                        {...congestionData.feedback.buttonPrimary}
                        variant="primary"
                        handleOnClick={(value) =>
                          scrollToElement(
                            value === 'conclusion' ? refConclusion : refCongestion,
                            adsContext.headerHeight
                          )
                        }
                      />
                    ) : null}

                    {congestionData.feedback.buttonSecondary ? (
                      <CongestionButton
                        {...congestionData.feedback.buttonSecondary}
                        variant="ghost"
                        handleOnClick={(value) =>
                          scrollToElement(
                            value === 'conclusion' ? refConclusion : refCongestion,
                            adsContext.headerHeight
                          )
                        }
                      />
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            </Box>
          </GridItem>
        </PageGrid>
      ) : null}
      {congestionData?.congestionData?.length ? (
        <Box ref={refCongestion}>
          <FollowUpSteps
            followUpSteps={congestionData.congestionData}
            conclusion={
              congestionData.conclusion ? (
                <Box width="100%" ref={refConclusion}>
                  <ConclusionBlock {...congestionData.conclusion} />
                </Box>
              ) : null
            }
          />
        </Box>
      ) : null}

      {!congestionData?.congestionData?.length && congestionData?.conclusion ? (
        <PageGrid>
          <GridItem columnStart={{ initial: '1', lg: '2' }} columnEnd={{ initial: '-1', lg: '-2' }}>
            <Box width="100%" paddingTop={12} ref={refConclusion}>
              <ConclusionBlock {...congestionData.conclusion} />
            </Box>
          </GridItem>
        </PageGrid>
      ) : null}
    </Box>
  );
};

type CongestionButtonProps = {
  handleOnClick: (anchor: 'congestion' | 'conclusion') => void;
} & SiteCoreButtonProps &
  Pick<ButtonProps, 'variant'>;

// Checks if the button has an anchor, and map it so we can scroll to the correct section.
// Otherwise we just render an Button link.
const CongestionButton = ({ handleOnClick, variant, text, ...button }: CongestionButtonProps) => {
  const anchor =
    button.anchor === 'congestion' || button.anchor === 'conclusion' ? button.anchor : null;

  return anchor ? (
    <Button variant={variant} onClick={() => handleOnClick(anchor)}>
      {text}
    </Button>
  ) : (
    <ButtonLink {...button} variant={variant}>
      {text}
    </ButtonLink>
  );
};

// Generated the data for the follow up step cards.
const setCongestionFollowUpSteps = (data?: CongestionProps[]): FollowUpStepProps[] => {
  const followUpSteps: FollowUpStepProps[] = [];

  // Creates the data for the cards
  data?.forEach((item) => {
    const consume = congestion(item.level.consume);
    const supply = congestion(item.level.supply);
    const endDate = getQuarterlyEndDate(item.latestEndDate);
    const solved = consume && supply;

    // only add unsolved items to the followUpSteps
    if (!solved) {
      const tag = endDate
        ? `Opgelost (verwacht): ${endDate.quarter}e kwartaal ${endDate.year}`
        : 'Datum onbekend';

      followUpSteps.push({
        title: item.name ? `Knelpunt: ${item.name}` : 'Knelpunt zonder projectnaam',
        tag: tag,
        type: solved ? 'checked' : undefined
      });
    }
  });

  return followUpSteps;
};

export type CongestionProps = {
  latestEndDate: string;
  latestEndDateSupply: string;
  latestEndDateConsume: string;
  name?: string;
  level: {
    supply?: string;
    consume?: string;
  };
};

type CongestionFeedbackProps = {
  description: string;
  buttonPrimary?: SiteCoreButtonProps;
  buttonSecondary?: SiteCoreButtonProps;
};

export type FeedbackAndConclusionProps = {
  feedback: CongestionFeedbackProps;
  conclusion?: ConclusionBlockProps;
};

type CongestionDataProps = {
  congestionData?: FollowUpStepProps[];
  congestions?: {
    consume: boolean;
    supply: boolean;
  };
} & FeedbackAndConclusionProps;

type FetchStatus = 'initial' | 'loading' | 'done' | 'error' | 'notFound';
