import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'lib/component-props';
import { OutagesSMSServiceView } from 'src/components/OutagesSMSService';

export type OutagesSMSServiceProps = {
  fields: {
    errorMessageText?: { value?: string };
    errorMessageTitle?: { value?: string };
    successMessageTitle?: { value?: string };
    checkboxLabel?: { value?: string };
    image?: {
      value?: {
        src?: string;
        alt?: string;
        width?: string;
        height?: string;
      };
    };
    submitButtonLabel?: { value?: string };
    title?: { value?: string };
    successMessageText?: { value?: string };
    text?: { value?: string };
    disableIfThereAreNoOutagesOnLocation?: { value: boolean };
  };
} & ComponentProps;

const OutagesSMSService = (props: OutagesSMSServiceProps) => {
  return props.fields ? <OutagesSMSServiceView {...props.fields} /> : null;
};

export default withDatasourceCheck()<OutagesSMSServiceProps>(OutagesSMSService);
